import { ReactElement, ReactNode } from 'react';
import PageWidth from 'styles/components/PageWidth';
import { Title } from 'styles/components/Title';
import Image from 'components/Image/Image';
import BellaFlowers from 'public/bella-paper-flowers.jpg';

const Text = ({ children }: { children: ReactNode }): ReactElement => (
  <p className="mt-4">{children}</p>
);

const WhatsInTheBox = (): ReactElement => (
  <div className="w-full bg-main text-white">
    <PageWidth>
      <div className="flex flex-col md:flex-row py-7">
        <div className="md:w-1/2 relative mb-2 md:mb-0 overflow-hidden">
          <Image
            src={BellaFlowers}
            alt="Making a Mexican box"
            objectFit="cover"
            height={350}
          />
        </div>

        <div className="flex-1 md:pl-10">
          <Title>What&apos;s In The Box</Title>

          <Text>
            ✅&nbsp;&nbsp;All the materials and tools for your chosen craft
          </Text>
          <Text>✅&nbsp;&nbsp;Easy to follow instruction cards</Text>
          <Text>✅&nbsp;&nbsp;Themed activity sheets</Text>
          <Text>✅&nbsp;&nbsp;Jokes, facts, mindfulness and more</Text>
          <Text>
            ✅&nbsp;&nbsp;Free disposable apron (if the craft includes paint)
          </Text>
          <Text>✅&nbsp;&nbsp;A whole load of fun!</Text>
        </div>
      </div>
    </PageWidth>
  </div>
);

export default WhatsInTheBox;
